$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky header
    // *
    // *
    $(window).scroll(function() {
        if($(window).scrollTop() >= 40) {
            $(".js-header-sticky").addClass("active");
        }else {
            $(".js-header-sticky").removeClass("active");
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav mobile
    // *
    // *
    $(".js-nav-mobile-button").click(function () {
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    })

    $(".js-nav-mobile-toggle").click(function () {
        $(this).toggleClass("open");
        $(this).siblings().slideToggle();
    })

    $(".js-nav-mobile-toggle.active").trigger("click");


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * asideButton
    // *
    // *
    $(".js-aside-button").click(function () {
        $(".js-aside-content").toggleClass("active");
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slider
	// *
	// *
	var $introSlider = $(".js-intro-slider");

	if ($introSlider.length){
		$introSlider.each(function(){
			$(this).slick({
				fade: false,
				dots: true,
				infinite: true,
				arrows: true,
                autoplay: true,
                autoplaySpeed: 3500,
				speed: 1000,
				prevArrow: $(".js-intro-slider-nav-prev"),
				nextArrow: $(".js-intro-slider-nav-next"),
                responsive: [
                        {
                          breakpoint: 1150,
                          settings: {
                            arrows:false
                          }
                        }
                      ]
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * teaser slider
	// *
	// *
	var $teaserSlider = $(".js-teaser-slider");

	if ($teaserSlider.length){
		$teaserSlider.each(function(){
			$(this).slick({
				fade: false,
				dots: true,
				infinite: true,
				arrows: false,
				autoplay: true,
                autoplaySpeed: 3500,
				speed: 1000
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * teaserCarousel
	// *
	// *
    var $teaserCarousel = $(".js-teaser-carousel");

	if ($teaserCarousel.length){
		$teaserCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
                autoplay: true,
                autoplaySpeed: 3500,
				speed: 1000,
				prevArrow: $(".js-teaser-carousel-nav-prev"),
				nextArrow: $(".js-teaser-carousel-nav-next"),
                slidesToShow: 3,
                slidesToScroll: 3,
                adaptiveHeight: true,
                responsive: [
                        {
                          breakpoint: 1350,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                          }
                        },
                        {
                          breakpoint: 1000,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                          }
                        }
                      ]
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * contentCarousel
	// *
	// *
    var $contentCarousel = $(".js-content-carousel");

	if ($contentCarousel.length){
		$contentCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: true,
				infinite: true,
				arrows: false,
                autoplay: true,
                autoplaySpeed: 3500,
				speed: 1000
			});
		});
	}




    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * fancybox
	// *
	// *
    $("[data-fancybox]").fancybox({
        buttons: [
            "close"
          ]
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slide down
	// *
	// *
    $(".js-image-content-button").click(function () {
        $(this).toggleClass("active");
        $(this).siblings(".js-image-content").slideToggle();
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slide down
	// *
	// *
    $(".js-content-carousel-button").click(function () {
        $(this).parents(".js-content-carousel-parent").toggleClass("active");
        $(this).siblings(".js-content-carousel-content").slideToggle();
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * popUp
    // *
    // *
    $("[data-popup]").click(function(event){
        event.preventDefault();
        var id = $(this).data("popup");
        window.location.hash = id;
        openPopUp($("#" + id));
    });

    // open popup if exists
    if(window.location.hash) {
        var hash = window.location.hash;
        if (hash && hash != 'a' && $(hash).length && $(".js-popup").length) {
            openPopUp($(hash));
        }
    }

    // open popUp
    function openPopUp($object) {
        $object.addClass("active");
        $("html, body").addClass("freeze");
    }

    // close popUp
    $(".js-popup-close").click(function(event){
        event.preventDefault();
        window.location.hash = "a";
        $(".js-popup").removeClass("active");
        $("html, body").removeClass("freeze");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * timeline
    // *
    // *
    var $timeline = $(".js-timeline");

    if ($timeline.length){
        $timeline.slick({
            fade: false,
            dots: true,
			prevArrow: $(".js-timeline-nav-prev"),
            nextArrow: $(".js-timeline-nav-next"),
            autoplay: false,
			variableWidth: true,
			infinite: false,
			centerMode: true,
            responsive: [
                    {
                      breakpoint: 450,
                      settings: {
                          adaptiveHeight: true
                      }
                    }
                  ]
        });
    }


});
